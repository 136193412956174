/* eslint-disable strict, no-eval */
"use strict";

function supportsAsyncAwait() {
    try {
        eval("async () => {}");
        return true;
    } catch (err) {
        return false;
    }
}

if (!supportsAsyncAwait()) {
    location.replace("/hodor");
}

